<template>
  <b-modal v-model="modalOpen" no-fade ref="edit-user" :no-close-on-backdrop="true" hide-footer hide-header hide-backdrop>
    <div class="row">
      <div class="col-md-12 stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-10">
                <h4 class="card-title mb-4">Editar Instancia</h4>
              </div>
              <div class="col-2">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="reset">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
            </div>
            <form class="forms-sample">
              <div class="form-group">
                <label for="name">Nombre</label>
                <input v-model="payload.name" type="text" class="form-control" id="name" placeholder="Nombre">
                <div v-if="errors.hasOwnProperty('name')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                  <small>{{errors.name[0]}}</small>
                </div>
              </div>
              <div class="row justify-content-end">
                <div class="col-auto">
                  <button @click.prevent="updateInstance" class="btn btn-gradient-info btn-md">Actualizar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'

export default {
  props: {
    payload: {
      type: Object,
      required: true
    }
  },
  name: 'UpdateInstance',
  data () {
    return {
      errors: {}
    }
  },
  methods: {
    ...mapActions('Instance', ['update']),
    ...mapMutations('Instance', ['TOGGLE_MODAL_UPDATE']),
    updateInstance () {
      this.update(this.payload)
        .then(() => {
          this.reset()
        })
        .catch((error) => {
          this.errors = error.response.data.errors
          if (!error.response.data.errors) {
            this.$swal(error.response.data.message, '', 'error')
          }
        })
    },
    reset () {
      this.name = null
      this.errors = {}
      this.TOGGLE_MODAL_UPDATE(false)
      this.$events.fire('refresh-table')
    }
  },
  computed: {
    ...mapGetters('Instance', ['modalUpdate']),
    modalOpen () {
      return this.modalUpdate
    }
  }
}
</script>
